import React from "react"
import { Layout } from "../components/layout"
import styles from "../styles/style"

import Button from "../components/button"
import { Link } from "gatsby"

//import { AiOutlineCloudUpload, AiFillLock } from "react-icons/ai"

//import { BsPatchQuestionFill } from "react-icons/bs"

//import { MdFingerprint } from "react-icons/md"

/* const features = [
  {
    name: "Push to deploy",
    description:
      "Morbi viverra dui mi arcu sed. Tellus semper adipiscing suspendisse semper morbi. Odio urna massa nunc massa.",
    icon: AiOutlineCloudUpload,
  },
  {
    name: "SSL certificates",
    description:
      "Sit quis amet rutrum tellus ullamcorper ultricies libero dolor eget. Sem sodales gravida quam turpis enim lacus amet.",
    icon: AiFillLock,
  },
  {
    name: "Simple queues",
    description:
      "Quisque est vel vulputate cursus. Risus proin diam nunc commodo. Lobortis auctor congue commodo diam neque.",
    icon: BsPatchQuestionFill,
  },
  {
    name: "Advanced security",
    description:
      "Arcu egestas dolor vel iaculis in ipsum mauris. Tincidunt mattis aliquet hac quis. Id hac maecenas ac donec pharetra eget.",
    icon: MdFingerprint,
  },
] */

export default function DeliveryAndCharges() {
  return (
    <Layout>
      <div className={` ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <section className="body-font ">
            <div className="container  mx-auto mb-6">
              <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full mt-4">
                <span className="text-green-gradient">Delivery & Charges</span>
              </h2>
              <p>
                Welcome to our delivery & charges section, please see below our
                delivery incentive, designed around our customer spending
                habits. You will also find the different delivery options that
                we offer here at H&S Pepper Co, and what to expect regarding the
                UK postal charges, for deliveries in and around the UK.
              </p>
            </div>
          </section>
          <section className="text-gray-700 body-font border-t border-gray-200 mt-6 mb-12">
            <div className="container mx-auto flex  md:flex-row flex-col items-center mt-8">
              <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-8 md:mb-0 items-center ">
                <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full mb-4">
                  <span className="text-green-gradient">Incentives</span>
                  {/* <br className="hidden lg:inline-block" />
                  <span className="text-green-gradient">orders over £80</span> */}
                </h2>

                <p className="mb-4 leading-relaxed">
                  If you live in the UK and spend £80 or more, you will qualify
                  for free delivery. Unfortunately, this doesn’t mean that our
                  couriers will deliver it for free, but it does mean that we’ll
                  pay the postage.
                </p>
                <p>
                  If you’re spending £80 or more on hot sauces, you’re either a
                  serious chilli head or someone with an impeccable taste in
                  gifts and, whichever it is, we reckon the least we can do is
                  pick up the tab for the postage.
                </p>
                <div className="flex justify-center mt-6">
                  <Link to="/products" alt="h&s pepper products">
                    <Button label="View Products" />
                  </Link>
                </div>
              </div>
              <div className="lg:max-w-lg lg:w-full md:w-1/2 ">
                <img
                  className="object-cover object-center rounded"
                  alt="hero"
                  src="https://dummyimage.com/720x600/edf2f7/a5afbd"
                />
              </div>
            </div>
          </section>

          <section className="text-gray-700 body-font border-t border-gray-200 mb-12">
            <div className="container mx-auto flex flex-wrap">
              <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full mt-6 mb-4">
                <span className="text-green-gradient">Options</span>
              </h2>
              <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                <img
                  alt="feature"
                  className="object-cover object-center h-full w-full"
                  src="https://dummyimage.com/600x600/edf2f7/a5afbd"
                />
              </div>
              <div className="flex flex-col flex-wrap  -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                <div className="flex flex-col mb-4 lg:items-start ">
                  <div className="flex items-center mb-4">
                    <div className="w-12 h-12  mr-3 inline-flex items-center justify-center rounded-full bg-green-gradient text-white flex-shrink-0">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className="w-6 h-6"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                      </svg>
                    </div>
                    <h2 className="text-xl title-font  font-bold">
                      <span className="text-green-gradient">
                        Delivery & charges
                      </span>
                    </h2>
                  </div>
                  <div className="flex-grow">
                    <p className="leading-relaxed text-base mb-4 text-left">
                      Orders with a weight of less than 2kg will be sent via
                      Royal Mail and, so long as Postman Pat does his job
                      properly, are normally delivered within three working
                      days. Orders with a weight of 2kg and over will be sent
                      via courier. This is a guaranteed next-day delivery
                      service (working days only) and is also fully trackable.
                    </p>
                    <p className="leading-relaxed text-base mb-4 text-left">
                      Orders are despatched Monday to Friday inclusive.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col mb-6 lg:items-start ">
                  <div className="flex items-center mb-3">
                    <div className="w-12 h-12 mr-3 inline-flex items-center justify-center rounded-full bg-green-gradient text-white flex-shrink-0">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className="w-6 h-6"
                        viewBox="0 0 24 24"
                      >
                        <circle cx="6" cy="6" r="3"></circle>
                        <circle cx="6" cy="18" r="3"></circle>
                        <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                      </svg>
                    </div>
                    <h2 className="text-xl title-font  font-bold">
                      <span className="text-green-gradient">
                        Overseas Delivery
                      </span>
                    </h2>
                  </div>
                  <div className="flex-grow">
                    {/* <h2 className="text-lg title-font  font-bold mb-3">
                        <span className="text-green-gradient">
                          Overseas Delivery
                        </span>
                      </h2> */}
                    <p className="leading-relaxed text-base text-left">
                      We can send orders to the following countries: Andorra,
                      Australia, Austria, Belgium, Bulgaria, Croatia, Czech
                      Republic, Denmark, Estonia, Finland, Germany, Gibraltar,
                      Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Malta,
                      Netherlands, Norway, Poland, Portugal, Romania, San
                      Marino, Slovakia, Slovenia, Sweden, Switzerland, Turkey,
                      Vatican, United States. The free rate only applies to UK
                      residence only.
                    </p>
                    <br />
                    <p className="leading-relaxed text-base text-left">
                      If you would like to place an international order, please
                      e-mail: international@hspepper.co.uk
                    </p>
                  </div>
                  <div className="flex justify-center">
                    <Link to="/products" alt="h&s pepper products">
                      <Button label="View Products" styles={`mt-6`} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="text-gray-700 body-font border-t border-gray-200">
            <div className="container mx-auto flex flex-wrap flex-col">
              <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full mt-4 mb-4">
                <span className="text-green-gradient">UK Postal Charges</span>
              </h2>
              <p className="leading-relaxed text-base mb-4">
                Postal charges are calculated on product weight including
                packaging. Just to give you an example, a typical 148ml bottle
                of hot sauce with packaging weighs approximately 500g.
              </p>
              <p className="font-semibold text-green-gradient">
                The free rate applies to UK Residence only, any oversea postal
                charges will be paid by the customer.
              </p>
            </div>

            <div className="container  mx-auto flex flex-wrap flex-col mb-10">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full text-left text-sm font-light">
                      <thead className="border-b font-medium dark:border-neutral-500">
                        <tr>
                          <th scope="col" className="px-6 py-4">
                            <h2 className="text-lg title-font  font-bold">
                              <span className="text-green-gradient">#</span>
                            </h2>
                          </th>
                          <th scope="col" className="px-6 py-4">
                            <h2 className="text-lg title-font  font-bold">
                              <span className="text-green-gradient">
                                weight
                              </span>
                            </h2>
                          </th>
                          <th scope="col" className="px-6 py-4">
                            <h2 className="text-lg title-font  font-bold">
                              <span className="text-green-gradient">price</span>
                            </h2>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-b dark:border-neutral-500">
                          <td className="whitespace-nowrap px-6 py-4 font-medium">
                            1
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            Up to 100g
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">£2.50</td>
                        </tr>
                        <tr className="border-b dark:border-neutral-500">
                          <td className="whitespace-nowrap px-6 py-4 font-medium">
                            2
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            Up to 250g
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">£2.50</td>
                        </tr>
                        <tr className="border-b dark:border-neutral-500">
                          <td className="whitespace-nowrap px-6 py-4 font-medium">
                            3
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            Up to 500g
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">£2.50</td>
                        </tr>
                        <tr className="border-b dark:border-neutral-500">
                          <td className="whitespace-nowrap px-6 py-4 font-medium">
                            4
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            Up to 750g
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">£2.95</td>
                        </tr>
                        <tr className="border-b dark:border-neutral-500">
                          <td className="whitespace-nowrap px-6 py-4 font-medium">
                            5
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            Up to 1kg
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">£3.95</td>
                        </tr>

                        <tr className="border-b dark:border-neutral-500">
                          <td className="whitespace-nowrap px-6 py-4 font-medium">
                            6
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            Up to 2kg
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">£4.95</td>
                        </tr>

                        <tr className="border-b dark:border-neutral-500">
                          <td className="whitespace-nowrap px-6 py-4 font-medium">
                            7
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            Up to 20kg
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">£4.95</td>
                        </tr>
                        <tr className="border-b dark:border-neutral-500">
                          <td className="whitespace-nowrap px-6 py-4 font-medium">
                            8
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            £80 or over
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">Free</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section className="text-gray-700 body-font">
            <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
              <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                  Free delivery on
                  <br className="hidden lg:inline-block" />
                  orders over £80
                </h1>
                <p className="mb-8 leading-relaxed">
                  If you spend £80 or more, you will qualify for free delivery.
                  Unfortunately, this doesn’t mean that our couriers will
                  deliver it for free, but it does mean that we’ll pay the
                  postage.
                </p>
                <p>
                  If you’re spending £80 or more on hot sauces, you’re either a
                  serious chilli head or someone with an impeccable taste in
                  gifts and, whichever it is, we reckon the least we can do is
                  pick up the tab for the postage.
                </p>
                <div className="flex justify-center mt-10">
                  <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                    products
                  </button>
                </div>
              </div>
              <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                <div className="flex flex-col">
                  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                      <div className="overflow-hidden">
                        <table className="min-w-full text-left text-sm font-light">
                          <thead className="border-b font-medium dark:border-neutral-500">
                            <tr>
                              <th scope="col" className="px-6 py-4">
                                #
                              </th>
                              <th scope="col" className="px-6 py-4">
                                First
                              </th>
                              <th scope="col" className="px-6 py-4">
                                Last
                              </th>
                              <th scope="col" className="px-6 py-4">
                                Handle
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="border-b dark:border-neutral-500">
                              <td className="whitespace-nowrap px-6 py-4 font-medium">
                                1
                              </td>
                              <td className="whitespace-nowrap px-6 py-4">Mark</td>
                              <td className="whitespace-nowrap px-6 py-4">Otto</td>
                              <td className="whitespace-nowrap px-6 py-4">@mdo</td>
                            </tr>
                            <tr className="border-b dark:border-neutral-500">
                              <td className="whitespace-nowrap px-6 py-4 font-medium">
                                2
                              </td>
                              <td className="whitespace-nowrap px-6 py-4">Jacob</td>
                              <td className="whitespace-nowrap px-6 py-4">
                                Thornton
                              </td>
                              <td className="whitespace-nowrap px-6 py-4">@fat</td>
                            </tr>
                            <tr className="border-b dark:border-neutral-500">
                              <td className="whitespace-nowrap px-6 py-4 font-medium">
                                3
                              </td>
                              <td className="whitespace-nowrap px-6 py-4">Larry</td>
                              <td className="whitespace-nowrap px-6 py-4">Wild</td>
                              <td className="whitespace-nowrap px-6 py-4">
                                @twitter
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </div>
    </Layout>
  )
}
